
import React, { useEffect } from "react"
import { Elements } from 'react-stripe-elements'
import Layout from "../components/layout"
import Hero from "../components/hero/Hero"
import Features from "../components/features/Features"
import Reviews from '../components/reviews/Reviews'
import Pricing from "../components/pricing/Pricing"
import Author from "../components/author/Author"
import Faq from "../components/faq/Faq"
import Footer from '../components/footer/Footer'
import PurchaseModal from "../components/purchase/components/PurchaseModal"

function useRedirectToWiki (location) {
  useEffect(() => {
    const hashIndex = location.search.indexOf('hash');
    const hasHash = hashIndex !== -1;

    if (hasHash) {
      const hash = location.search.substring(hashIndex);
      window.location.href = `https://wiki.solidbook.io?${hash}`
    }
  }, location.search)
}

const IndexPage = ({ location }) => {
  useRedirectToWiki(location);
  return (
    <Layout location={location}>
      <Elements>
        <PurchaseModal/>
      </Elements>
      <Hero/>
      <Features/>
      <Reviews/>
      <Pricing/>
      <Author/>
      <Faq/>
      <Footer/>
    </Layout>
  )
}

export default IndexPage
