
import React from 'react'
import "./FeaturedReview.sass"
import { graphql, useStaticQuery } from 'gatsby'

const GET_FEATURED_REVIEW = graphql`
  {
    allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}}}) {
      edges {
        node {
          frontmatter {
            avatar {
              publicURL
            }
            bio
            name
          }
          html
        }
      }
    }
  }
`

const FeaturedReview = () => {
  const { allMarkdownRemark } = useStaticQuery(GET_FEATURED_REVIEW);
  const review = allMarkdownRemark.edges.map((e) => ({ ...e.node}))[0];
  return (
    <div className="featured-review">
      <div dangerouslySetInnerHTML={{ __html: review.html }}></div>

      <div className="reviewer-container">
        <img src={review.frontmatter.avatar.publicURL}/>
        <div className="name-and-bio">
          <div className="name">{review.frontmatter.name}</div>
          <div className="separator">{`/`}</div>
          <div className="bio">{review.frontmatter.bio}</div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedReview;