
import React from 'react' 
import "./Reviews.sass"
import FeaturedReview from './FeaturedReview'
import { graphql, useStaticQuery } from 'gatsby'
const tweet = require('../../images/twitter.svg');

const GET_ALL_REVIEWS = graphql`
  {
  allMarkdownRemark(
    filter: {frontmatter: {featured: {eq: false}}}, 
    sort: {fields: frontmatter___order}
  ) {
    edges {
      node {
        frontmatter {
          avatar {
            publicURL
          }
          bio
          name
          tweet
        }
        html
      }
    }
  }
}

`

const Review = ({ html, frontmatter }) => (
  <div className="review">
    <div dangerouslySetInnerHTML={{ __html: html }}></div>
    <div className="review-author">
      <img src={frontmatter.avatar.publicURL}/>
      <div className="name-and-bio">
        <div className="name">{frontmatter.name} {frontmatter.tweet ? (
      <a target="_blank" className="tweet" href={frontmatter.tweet}><img src={tweet}/></a>
    ) : ''}</div>
        <div className="bio">{frontmatter.bio}</div>
      </div>
    </div>
  </div>
)

const Reviews = () => {
  const { allMarkdownRemark } = useStaticQuery(GET_ALL_REVIEWS);
  const reviews = allMarkdownRemark.edges.map((e) => ({ ...e.node }));

  const leftColumn = reviews.slice(0, Math.floor(reviews.length / 2));
  const rightColumn = reviews.slice(Math.floor(reviews.length / 2));

  return (
    <section className="reviews">
      <div className="content-container">
        <h2>Reader reviews</h2>
        <FeaturedReview />
        <div className="reviews-container">
          <div className="column">
            {leftColumn.map((review, i) => (
              <Review key={i} {...review}/>
            ))}
          </div>
          <div className="column">
            {rightColumn.map((review, i) => (
              <Review key={i} {...review}/>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Reviews;

