
import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import "./Features.sass"

const GET_FEATURES = graphql`
  {
    allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "features"}}},
      sort: {fields: frontmatter___order}
    ) {
      edges {
        node {
          html
          frontmatter {
            templateKey
            order
            title
          }
        }
      }
    }
  }
`

const Features = () => {
  const { allMarkdownRemark } = useStaticQuery(GET_FEATURES);
  const features = allMarkdownRemark.edges.map((e) => ({ ...e.node }));
  
  return (
    <section className="features">
      <div className="content-container">
        <h2 className="features-title">What will you learn?</h2>
        <p className="table-of-contents">Here's what's important</p>
      
        {features.map((feature, i) => (
          <div key={i} className="feature">
            <h3>{feature.frontmatter.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: feature.html }}></div>
          </div>
        ))}

        <p className="table-of-contents">View the full <Link to="/table-of-contents">table of contents <b>→</b></Link></p>
      </div>
    </section>
  )
}

export default Features;
